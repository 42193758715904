<script setup>
import { computed, onMounted, ref, watch, onBeforeMount } from 'vue';
import axios from 'axios';
import md5 from 'js-md5'
import OfSpinner from './components/OfSpinner.vue'

//	Dev
//const businessPaymentThreshold = 500;
//const fallbackEmail = ref('KnaekCancerCallcenter@cancer.dk');
//const formId = "9232513";
//const formGuid = "2922e7f1-aa81-4e1f-9b5f-aff39b678869";
//const dataReceiverUrl = 'https://development.datareceiver.api.onlinefundraising.dk';
//const communicationCollectionGuid_Business_Invoice = '064904fe-ba41-411a-aa5b-9e20eb76266e';
//const communicationCollectionGuid_Business_Card = '064904fe-ba41-411a-aa5b-9e20eb76266e';
//const communicationCollectionGuid_Individual = '064904fe-ba41-411a-aa5b-9e20eb76266e';
//const purposeAccountingCode = 'knaekcancer';
//const callcenterUrl = 'https://development.onlinefundraising.dk';
//const errorReturnPageUrl = 'https://development.onlinefundraising.dk/utility/kc-result-landing-page/';
//const successReturnPageUrl = 'https://development.onlinefundraising.dk/utility/kc-result-landing-page/';

//	Prod.
const businessPaymentThreshold = 10000;
const fallbackEmail = ref('KnaekCancerCallcenter@cancer.dk');
const formId = "981564";
const formGuid = "e9de03e9-0c1c-44ac-8beb-334a0295aa31";
const dataReceiverUrl = "https://datareceiver.api.v1.onlinefundraising.dk";
const communicationCollectionGuid_Business_Invoice = '283f9923-1d17-4981-b211-05296aab4826';
const communicationCollectionGuid_Business_Card = '827707e1-caaa-467e-82c9-b490e30e6d58';
const communicationCollectionGuid_Individual = '92711c71-5c56-4a5b-b077-c9e76b7ec32e';
const purposeAccountingCode = 'knaekcancer';
const callcenterUrl = 'https://knaekcancer.callcenter.onlinefundraising.dk';
const errorReturnPageUrl = 'https://knaekcancer.callcenter.onlinefundraising.dk/result-landing-page/';
const successReturnPageUrl = 'https://knaekcancer.callcenter.onlinefundraising.dk/result-landing-page/';

let skipLookup = false;
let checker;

const formData = ref({
	contactType: 'individual',
	currencyCode: 'DKK',
	paymentType: 'Single',
	paymentMethodType: 'Card',
	firstName: '',
	lastName: '',
	displayName: '',
	name: computed(
		() => formData.value.firstName + ' ' + formData.value.lastName
	),
	amount: computed(
		() => amountFormatted.value ? unformatAmount(amountFormatted.value) : null
	),
	taxDeductable: true,
	dataSetTransactional: true,
	dataSetThirdParty: computed(
		() => formData.value.paymentMethodType == 'Invoice' ? true : false
	),
	communicationCollectionGuid: computed(
		() => formData.value.contactType == 'individual' ? communicationCollectionGuid_Individual : (
			formData.value.paymentMethodType == 'Invoice' ? communicationCollectionGuid_Business_Invoice : communicationCollectionGuid_Business_Card
		)
	),
	dataSetChannel: 'callcenter',
	errorReturnPageUrl: errorReturnPageUrl,
	successReturnPageUrl: successReturnPageUrl
})
const amountFormatted = ref(null);

const dawaBusy = ref(false);
const dawaSuggestions = ref([]);
const openIframe = ref(false);
const loading = ref(false);
const iframeSrc = ref(null);
const displayNameUpdated = ref(false);
const cprMessage = ref(null);
const addressMessage = ref(null);
const paymentSuccessful = ref(false);
const paymentFailed = ref(false);

/* const isMobilePaymentMethodType = computed(() => {
	return (formData.value.paymentMethodType == 'MobilePayOnline' || formData.value.paymentMethodType == 'Test');
}) */
const isIndividual = computed(() => {
	return formData.value.contactType == 'individual';
})
const isBusiness = computed(() => {
	return formData.value.contactType == 'business';
})
const isLargeDonation = computed(() => {
	return formData.value.amount >= businessPaymentThreshold;
})
const isAnonymized = computed(() => {
	return (!isLargeDonation.value || formData.value.requestedAnonymized);
})
const paymentTypeTranslated = computed(() => {
	return 'Enkelt betaling';
})
function hasSessionData() {
	return (sessionStorage.getItem('phone') ? true : false);
}
const paymentMethodTypeTranslated = computed(() => {
	switch (formData.value.paymentMethodType) {
		case 'MobilePayOnline':
			return 'MobilePay';
		case 'Card':
			return 'Betalingskort';
		case 'Invoice':
			return 'Faktura';
		default:
			return formData.value.paymentMethodType;
	}
})
const submitText = computed(() => {
	switch (formData.value.paymentMethodType) {
		case 'MobilePayOnline':
			return 'Gå til betaling';
		case 'Card':
			return 'Gå til betaling';
		case 'Invoice':
			return 'Afslut faktura';
		default:
			return 'Næste';
	}
})
/**
 * Reset
 */
function resetForm() {
	goBack();

	amountFormatted.value = null;
	formData.value = {
		contactType: 'individual',
		currencyCode: 'DKK',
		paymentType: 'Single',
		paymentMethodType: 'Card',
		firstName: '',
		lastName: '',
		displayName: '',
		name: computed(
			() => formData.value.firstName + ' ' + formData.value.lastName
		),
		amount: computed(
			() => amountFormatted.value ? unformatAmount(amountFormatted.value) : null
		),
		taxDeductable: true,
		dataSetTransactional: true,
		dataSetThirdParty: computed(
			() => formData.value.paymentMethodType == 'Invoice' ? true : false
		),
		communicationCollectionGuid: computed(
			() => formData.value.contactType == 'individual' ? communicationCollectionGuid_Individual : (
				formData.value.paymentMethodType == 'Invoice' ? communicationCollectionGuid_Business_Invoice : communicationCollectionGuid_Business_Card
			)
		),
		dataSetChannel: 'callcenter',
		errorReturnPageUrl: errorReturnPageUrl,
		successReturnPageUrl: successReturnPageUrl
	}
}
function goBack() {
	paymentSuccessful.value = false;
	paymentFailed.value = false;
	openIframe.value = false;
	iframeSrc.value = null;
	clearInterval(checker);
}

/**
 * Enforce typing rules
 * @param {String} rule 
 * @param {Object} e 
 */
function enforceRule(rule, e) {
	if (rule === 'digits' || rule === 'number') {
		console.log(e)
		if ([191, 219].includes(e.keyCode)) {
			e.preventDefault();
		} else if (e.shiftKey) {
			e.preventDefault();
		}
		else if ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true)) {
			return;
		} else if ([8, 46, 9, 27, 13, 35, 36, 37, 39].includes(e.keyCode)) {
			return;
		} else if ((e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 95 && e.keyCode < 106)) {
			if (rule === 'number' && ((e.keyCode == 48 || e.keyCode == 96) && e.target.value.length < 1)) {
				e.preventDefault();
			}
			return;
		}

		e.preventDefault();

	} else if (rule === "text") {

		const keyCode = (e.keyCode ? e.keyCode : e.which);
		if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106)) {
			e.preventDefault();
		}

	} else if (rule === "email") {

		const keyCode = (e.keyCode ? e.keyCode : e.which);
		if (keyCode === 32) {
			e.preventDefault();
		}
	}
}

/**
 * DAWA handling
 */
let Typing;
function inputAddress() {

	formData.value.dawa_id = null;
	formData.value.countryCode = null;

	clearTimeout(Typing);
	Typing = setTimeout(queryAddress, '500', formData.value.address);
}

/**
 * Query Dataforsyningen's API ( https://dawadocs.dataforsyningen.dk/dok/api/autocomplete )
 */
function queryAddress(address, postCode, city) {

	if (address) {

		dawaSuggestions.value = [];

		if (dawaBusy.value) {

			console.log("Request blocked as busy");
			return;
		} else if (address.length < 5) {
			//  Log
			console.log("Request blocked as too short address");
			return;
		}

		let pageSize;

		if (postCode && city) {
			pageSize = "10";
		} else if (postCode || city) {
			pageSize = "50";
		} else {
			pageSize = "100";
		}

		const parameters = `q=${address}${postCode ? ' ' + postCode : ''}${city ? ' ' + city : ''}`

		const query =
			`https://api.dataforsyningen.dk/autocomplete?type=adresse&startfra=adgangsadresse&fuzzy=true&per_side=${pageSize}&${parameters}`;

		dawaBusy.value = true;

		axios
			.get(query)
			.then((response) => {
				if (response.data) {

					dawaBusy.value = false;
					if (response.data.length == 1) {
						applySuggestion(response.data[0]);
					} else {
						dawaSuggestions.value = response.data;
					}
				}
			})
			.catch((error) => {
				dawaBusy.value = false;

				console.log(error)
			});
	}
}
function applySuggestion(suggestion) {

	dawaSuggestions.value = [];

	let streetName = suggestion.data.adresseringsvejnavn,
		streetNumber = suggestion.data.husnr,
		floor = suggestion.data.etage,
		door = suggestion.data.dør,
		// address2 = suggestion.data.supplerendebynavn,
		postCode = suggestion.data.postnr,
		city = suggestion.data.postnrnavn,
		// municipalityCode = suggestion.data.kommunekode,
		id = suggestion.data.id,
		countryCode = "DK",
		address =
			streetName +
			" " +
			streetNumber +
			(floor ? ", " + floor + "." : "") +
			(door ? " " + door : "");

	//  Temporary address

	formData.value.address = address;
	formData.value.postCode = postCode;
	formData.value.city = city;
	formData.value.dawa_id = id;
	formData.value.countryCode = countryCode;

	if (suggestion.type === "adgangsadresse") {
		//  Log
		console.log("Request when address is not unique");

		queryAddress(address, postCode, city);
	}
}
function formatAmount(amount) {
	return Number(unformatAmount(amount)).toLocaleString('da-DK');
}
function unformatAmount(amount) {
	return amount.toString().replace(/\./g, '')
}
function amountChange() {
	if (amountFormatted.value == 0 || isNaN(unformatAmount(amountFormatted.value))) {
		amountFormatted.value = null;
	}
	else if (amountFormatted.value.length) {
		amountFormatted.value = formatAmount(amountFormatted.value);
	}
}
async function lookupCpr() {
	if (formData.value.nationalId) {
		const data = {
			merchantId: 'knaekcancer',
			operationType: 'validateNationalId',
			countryCode: 'DK',
			nationalId: formData.value.nationalId,
		}

		const jsonData = JSON.stringify(data);

		const response = await axios.post(
			dataReceiverUrl + '/form/Validate',
			jsonData,
			{
				headers: {
					'Content-Type': 'application/json',
					requestMD5: md5(jsonData),
				}
			}
		)

		return response.data.success;

	}
	return true;
}
function checkValidity() {
	document.querySelectorAll('#event-form input').forEach((element) => {
		const input = element;
		const validity = input.validity;

		const rx = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

		if (validity.valueMissing) {
			input.setCustomValidity(`Feltet er obligatorisk`);
		} else if (validity.tooLong) {

			input.setCustomValidity(`Indholdet er for langt`);
		} else if (validity.tooShort) {
			input.setCustomValidity(`Indholdet er for kort`);
		} else if (validity.patternMismatch) {
			input.setCustomValidity(`Indholdet er ugyldigt`);

		} else if (input.getAttribute('type') == 'email' && !rx.test(formData.value.email)) {
			input.setCustomValidity(`Email er ugyldig`);
		} else {
			input.setCustomValidity('');
		}


		input.reportValidity();
	});
}

/**
 * Submit handling
 */
async function submit() {

	cprMessage.value = null;

	if (isIndividual.value && !await lookupCpr()) {
		cprMessage.value = 'Cpr.nr er ugyldigt';
		return;
	}

	loading.value = true;

	if (!dawaSuggestions.value.length) {

		//	Remove display name if requested anonymised or amount is below limit
		if (formData.value.requestedAnonymized || !isLargeDonation.value) {
			delete formData.value.displayName;
		}

		const phone = formData.value.phone;
		const msisdn = phone.length === 8 ? "45" + phone : phone;

		// here
		const data = {
			operationType: 'submitForm',
			countryCode: formData.value.countryCode,
			purposeAccountingCode: purposeAccountingCode,
			formGuid: formGuid,
			form_id: formId,
			formResult: {
				...formData.value,
				msisdn: msisdn,
				amount: Number(formData.value.amount).toFixed(2),
				name: formData.value.firstName + ' ' + formData.value.lastName,
				paymentTypeTranslated: paymentTypeTranslated.value,
				paymentMethodTypeTranslated: paymentMethodTypeTranslated.value
			},
		}

		const jsonData = JSON.stringify(data);

		console.log(data);

		axios.post(
			dataReceiverUrl + '/form/Validate',
			jsonData,
			{
				headers: {
					'Content-Type': 'application/json',
					requestMD5: md5(jsonData),
				}
			}
		).then((result) => {

			//	Instant confirmation
			if (formData.value.paymentMethodType == 'Invoice') {

				if (result.data.success) {
					paymentSuccessful.value = true;
				}
				else {
					paymentFailed.value = true;
				}
				loading.value = false;
			}

			//  Iframe handling
			else {

				openIframe.value = true;
				iframeSrc.value = result.data.initialUserRedirectUrl;
				loading.value = false;

				saveSessionStorage()

				checker = setInterval(() => {
					document.getElementById("gatewayIframe").contentWindow.postMessage({
						"request": "checkStatus"
					}, callcenterUrl); // TODO
				}, 5000);

				//  Clear status check
				setTimeout(() => { clearInterval(checker) }, 30000);

				//  Respond to callback
				window.addEventListener('message', function (event) {

					// TODO
					if (event.origin !== callcenterUrl) {
						return;
					}

					console.log(event.data.state);

					if (event.data.state === 'Complete') {
						paymentSuccessful.value = true;
						clearSessionStorage();
						clearInterval(checker);
					} else if (event.data.state === 'Failed') {
						paymentFailed.value = true;
						clearInterval(checker);
					} else if (event.data.state === 'SoftError') {
						paymentFailed.value = true;
						clearInterval(checker);
					} else if (event.data.state === 'Rejected') {
						paymentFailed.value = true;
						clearInterval(checker);
					} else if (event.data.state === 'SessionExpired') {
						paymentFailed.value = true;
						clearInterval(checker);
					} else {
						paymentFailed.value = true;
						clearInterval(checker);
					}
				});
			}

		}).catch((error) => {

			console.log(error)
			loading.value = false;
		})
	} else {
		loading.value = false;
		addressMessage.value = 'Vælg en adresse fra listen eller tryk "Accepter", hvis den ikke kan findes.';
	}
}
function saveSessionStorage() {
	const propsToSave = ['contactType', 'address', 'city', 'dawa_id', 'email', 'firstName', 'lastName', 'paymentMethodType', 'phone', 'postCode', 'nationalId', 'businessCode', 'displayName', 'countryCode', 'companyName']

	propsToSave.forEach(key => {
		if (formData.value[key]) {
			sessionStorage.setItem(key, formData.value[key]);
		} else {
			sessionStorage.removeItem(key);
		}
	});

	sessionStorage.setItem('amount', amountFormatted.value);
}
function loadSessionStorage() {
	const propsToLoad = ['contactType', 'address', 'city', 'dawa_id', 'email', 'firstName', 'lastName', 'paymentMethodType', 'phone', 'postCode', 'nationalId', 'businessCode', 'displayName', 'countryCode', 'companyName']
	skipLookup = true;
	propsToLoad.forEach(key => {
		const value = sessionStorage.getItem(key);

		if (value) {
			formData.value[key] = value;
		}
	});

	amountFormatted.value = sessionStorage.getItem('amount');

	setTimeout(() => { skipLookup = false; }, 300);
}
function clearSessionStorage() {
	const propsToClear = ['contactType', 'address', 'city', 'dawa_id', 'email', 'firstName', 'lastName', 'paymentMethodType', 'phone', 'postCode', 'nationalId', 'businessCode', 'displayName', 'countryCode', 'companyName', 'amount']

	propsToClear.forEach(key => {
		const value = sessionStorage.getItem(key);

		if (value) {
			sessionStorage.removeItem(key);
		}

	});
}
/**
 * Watchers
 */
watch(() => formData.value.businessCode, (businessCode) => {
	if (!skipLookup && businessCode && businessCode.length == 8) {
		axios.get(
			"https://cvrapi.dk/api?country=dk&vat=" + businessCode
		).then((result) => {

			const cvrData = result.data

			formData.value.companyName = cvrData.name ?? formData.value.companyName;
			formData.value.postCode = cvrData.zipcode ?? formData.value.postCode;
			formData.value.phone = cvrData.phone ?? formData.value.phone;
			formData.value.email = cvrData.email ?? formData.value.email;
			formData.value.address = cvrData.address ?? formData.value.address;
			formData.value.postCode = cvrData.zipcode ?? formData.value.postCode;
			formData.value.city = cvrData.city ?? formData.value.city;

			//  TODO : hvis cvr opslag returnerer en adresse skal dawa tjekke den.
			queryAddress(formData.value.address);


		}).catch((error) => {
			console.log(error)
		})
	}
})
/**
 * Watchers
 */
watch(() => formData.value.phone, (phone) => {
	console.log(skipLookup)
	if (!skipLookup && isIndividual.value && phone && phone.length == 8) {

		const jsonData = JSON.stringify({ phone: phone });

		axios.post(
			"https://utility.onlinefundraising.dk/phonenumber-lookup/",
			jsonData,
			{
				headers: {
					"Content-Type": 'application/json'
				}
			}
		).then((result) => {
			const data = result.data

			formData.value.firstName = data.firstName ?? formData.value.firstName;
			formData.value.lastName = data.lastName ?? formData.value.lastName;
			formData.value.address = data.address ?? formData.value.address;
			formData.value.postCode = data.postCode ?? formData.value.postCode;
			formData.value.city = data.city ?? formData.value.city;

			queryAddress(formData.value.address);

		}).catch((error) => {
			console.log(error)
		})
	}
})
//  Todo : Combine "firstName" and "lastName" into hidden: "name" separated by space
watch(() => formData.value.firstName, (firstName) => {
	if (!displayNameUpdated.value && isIndividual.value) {
		formData.value.displayName = String(firstName + ' ' + formData.value.lastName).substring(0, 35);
	}
})
watch(() => formData.value.lastName, (lastName) => {
	if (!displayNameUpdated.value && isIndividual.value) {
		formData.value.displayName = String(formData.value.firstName + ' ' + lastName).substring(0, 35);
	}

})
watch(() => formData.value.companyName, (companyName) => {
	if (!displayNameUpdated.value && isBusiness.value) {
		formData.value.displayName = companyName.substring(0, 35);
	}

})
watch(() => dawaSuggestions.value, () => {
	addressMessage.value = null;
})
watch(() => isIndividual.value, () => {
	if (!isIndividual.value) {
		cprMessage.value = null;
	}
})


onBeforeMount(() => {
	const searchURL = new URL(window.location);
	formData.value.phone = searchURL.searchParams.get('phone')
})
onMounted(() => {
	const form = document.getElementById('event-form');

	form.addEventListener('keydown', function (e) {
		const inputs = Array.prototype.slice.call(
			document.querySelectorAll('#event-form input')
		);

		if (e.key === "Enter" || e.keyCode === "13") {
			e.preventDefault();
			const activeInput = document.activeElement;
			const activeInputIndex = inputs.indexOf(activeInput);
			const nextinputIndex =
				(activeInputIndex + 1) % inputs.length;
			const input = inputs[nextinputIndex];
			input.focus();
		}
	})

})
</script>

<template>
	<header>
		<div>
			<div>
				<img style="height: 59px;position: absolute; top: 0px; right: 0;"
					:src="require(`@/assets/images/KC_Logo_Horisontal-Positiv_RGB.jpg`)">

			</div>
			<div class="actions">
				<button type="button" class="reset" @click="resetForm"> Nulstil / afbryd </button>
				<button v-if="!openIframe && hasSessionData()" type="button" class="reset" @click="loadSessionStorage">
					Genudfyld </button>
			</div>
		</div>
	</header>

	<div class="">
		<form id="event-form" @submit.prevent="submit()">
			<fieldset
				:disabled="openIframe || (formData.paymentMethodType == 'Invoice' && (paymentSuccessful || paymentFailed))">
				<div class="row">

					<!-- contactType -->
					<div class="col-sm-10 col-sm-offset-1">
						<div class="contact-type">

							<ul class="radio-group horizontal">

								<li>
									<label for="contactTypeIndividual">
										<input type="radio" v-model="formData.contactType" id="contactTypeIndividual"
											name="contactType" value="individual">Privat
										<span class="check"></span>
									</label>
								</li>

								<li>
									<label for="contactTypeBusiness">
										<input type="radio" v-model="formData.contactType" id="contactTypeBusiness"
											name="contactType" value="business">Virksomhed
										<span class="check"></span>
									</label>
								</li>

							</ul>

						</div>
					</div>
					<div class="col-sm-10 col-sm-offset-1">

						<!-- amount -->
						<div class="field">
							<div>
								<label for="amount">Beløb i DKK</label>
							</div>
							<div>
								<input v-model="amountFormatted" id="amount" type="tel" name="amount" min="1"
									maxlength="10" required autocomplete="nope" @keydown="enforceRule('number', $event)"
									@input="amountChange" oninput="this.setCustomValidity(' ')">
							</div>
						</div>

						<!-- businessCode -->
						<div class="field" v-if="isBusiness">
							<div>
								<label for="businessCode">CVR-nr.</label>
							</div>
							<div>
								<input v-model="formData.businessCode" id="businessCode" type="tel" minlength="8"
									maxlength="8" name="businessCode" autocomplete="nope"
									@keydown="enforceRule('digits', $event)" oninput="this.setCustomValidity(' ')">
							</div>
						</div>

						<!-- phone -->
						<div class="field">
							<div>
								<label v-if="isBusiness" for="phone">Virksomhedens hovedtlf.nr</label>
								<label v-else for="phone">Telefonnr</label>
							</div>
							<div>
								<input v-model="formData.phone" id="phone" type="tel" name="phone" minlength="8"
									maxlength="8" autocomplete="nope" required @keydown="enforceRule('digits', $event)"
									oninput="this.setCustomValidity(' ')">
							</div>
						</div>
						<!-- companyName -->
						<div class="field" v-if="isBusiness">
							<div> <label for="companyName">Firmanavn</label>
							</div>
							<div>
								<input v-model="formData.companyName" id="companyName" type="text" minlength="2"
									maxlength="200" autocapitalize="sentences" name="" autocomplete="nope" required
									oninput="this.setCustomValidity(' ')">
							</div>
						</div>

						<!-- firstName -->
						<div class="field">
							<div> <label for="firstName" v-if="isBusiness">Fornavn for kontaktperson</label>
								<label for="firstName" v-else>Fornavn</label>
							</div>
							<div>
								<input v-model="formData.firstName" id="firstName" type="text" minlength="1"
									maxlength="100" autocapitalize="sentences" name="firstName" autocomplete="nope"
									required @keydown="enforceRule('text', $event)"
									oninput="this.setCustomValidity(' ')">
							</div>
						</div>

						<!-- lastName -->
						<div class="field">
							<div>
								<label for="firstName" v-if="isBusiness">Efternavn for kontaktperson</label>
								<label for="lastName" v-else>Efternavn</label>
							</div>
							<div>
								<input v-model="formData.lastName" id="lastName" type="text" minlength="1"
									maxlength="100" autocapitalize="sentences" name="lastName" autocomplete="nope"
									required @keydown="enforceRule('text', $event)"
									oninput="this.setCustomValidity(' ')">
							</div>
						</div>

						<!-- requestedAnonymized -->
						<div v-if="isLargeDonation" class="field">
							<ul class="checkbox-group vertical">
								<li>
									<label for="requestedAnonymized">
										<input v-model="formData.requestedAnonymized" type="checkbox"
											name="requestedAnonymized" id="requestedAnonymized">Ønskes IKKE vist
										på TV
										<span class="check"></span>
									</label>
								</li>
							</ul>
						</div>

						<!-- displayName -->
						<div v-if="!isAnonymized" class="field">
							<div> <label for="displayName">Visningsnavn på TV</label></div>
							<div>
								<input v-model="formData.displayName" id="displayName" type="text" minlength="2"
									name="displayName" autocomplete="nope" maxlength="35"
									@change="displayNameUpdated = true" required oninput="this.setCustomValidity(' ')">
							</div>
							<small>Max 35 tegn</small>
						</div>

						<!-- email -->
						<div class="field">
							<div>
								<label for="email">Email</label>
							</div>
							<div>
								<input v-model="formData.email" id="email" type="email" minlength="8" name="email"
									autocomplete="nope" required="" @keydown="enforceRule('email', $event)"
									@change="duplicateEmail" oninput="this.setCustomValidity(' ')">
							</div>

							<small class="no-email" @click="formData.email = fallbackEmail">Klik her hvis ingen
								email</small>

						</div>

						<!-- nationalId -->
						<div class="field" v-if="isIndividual">
							<div>
								<label for="nationalId">CPR-nr., hvis du ønsker fradrag?</label>
							</div>
							<div>
								<input v-model="formData.nationalId" id="nationalId" type="tel" minlength="10"
									maxlength="10" name="nationalId" autocomplete="nope"
									@keydown="enforceRule('digits', $event)" oninput="this.setCustomValidity(' ')">
							</div>

							<small>Må ikke benyttes ved fælles indsamlinger</small>


						</div>
						<p v-if="cprMessage" class="error">{{ cprMessage }}</p>

						<!-- address -->
						<div class="field">
							<div>
								<label for="address">Adresse</label>
							</div>

							<div>
								<input v-model="formData.address" id="address" type="text" minlength="2" maxlength="200"
									autocapitalize="sentences" name="address" autocomplete="nope" required
									@input="inputAddress" oninput="this.setCustomValidity(' ')">
							</div>

							<button v-if="dawaSuggestions.length" type="button" class="accept-address"
								@click="dawaSuggestions = []">Accepter</button>

							<ul v-if="dawaSuggestions.length" class="dawa-suggestions">
								<li v-for="suggestion in dawaSuggestions" :key="suggestion"
									@click="applySuggestion(suggestion)">
									<div
										:class="[{ suggestion: suggestion.type == 'adgangsadresse' }, { valid: suggestion.type == 'adresse' }]">
										<span>{{ suggestion.forslagstekst }}</span>
										<span>
											{{ suggestion.type == 'adgangsadresse' ? 'Forslag' : 'Gyldig' }}
										</span>
									</div>
								</li>
							</ul>


						</div>

						<div class="post-and-city">

							<!-- postCode and city -->
							<div class="field postcode">
								<div>
									<label for="postCode">Postnr. og by</label>
								</div>
								<div>
									<input v-model="formData.postCode" id="postCode" type="text" minlength="3"
										name="postCode" autocomplete="nope" required
										@keydown="enforceRule('digits', $event)" placeholder="Postnr."
										@input="[formData.countryCode = null, formData.dawa_id = null]"
										oninput="this.setCustomValidity(' ')">

									<input v-model="formData.city" id="city" type="text" minlength="2" name="city"
										autocomplete="nope" placeholder="By" required
										@input="[formData.countryCode = null, formData.dawa_id = null]"
										oninput="this.setCustomValidity(' ')">
								</div>
							</div>



						</div>

						<p v-if="addressMessage" class="error">{{ addressMessage }}</p>

					</div>

					<!-- paymentMethodType -->
					<div class="col-sm-10 col-sm-offset-1">
						<div class="payment-method">
							<ul class="radio-group horizontal">

								<!-- MobilePay -->
								<li v-if="false">
									<label for="paymentMethodTypeMobilePay">
										<input v-model="formData.paymentMethodType" type="radio"
											id="paymentMethodTypeMobilePay" name="paymentMethodType"
											value="MobilePayOnline" checked="">MobilePay
										<span class="check"></span>
									</label>
								</li>

								<!-- Card -->
								<li>
									<label for="paymentMethodTypeCard">
										<input v-model="formData.paymentMethodType" type="radio"
											id="paymentMethodTypeCard" name="paymentMethodType"
											value="Card">Betalingskort
										<span class="check"></span>
									</label>
								</li>

								<!-- Betalingsservice -->
								<li v-if="false" class="field-for-individual">
									<label for="paymentMethodTypeBetalingsservice">
										<input v-model="formData.paymentMethodType" type="radio"
											id="paymentMethodTypeBetalingsservice" name="paymentMethodType"
											value="Betalingsservice">Betalingsservice
										<span class="check"></span>
									</label>
								</li>

								<!-- Invoice -->
								<li v-if="isBusiness" class="field-for-business hidden">
									<label for="paymentMethodTypeInvoice">
										<input v-model="formData.paymentMethodType" type="radio"
											id="paymentMethodTypeInvoice" name="paymentMethodType"
											value="Invoice">Faktura
										<span class="check"></span>
									</label>
								</li>

								<!-- TODO: Remove Test -->
								<li v-if="false">
									<label for="paymentMethodTypeTest">
										<input v-model="formData.paymentMethodType" type="radio"
											id="paymentMethodTypeTest" name="paymentMethodType" value="Test">Test
										<span class="check"></span>
									</label>
								</li>

							</ul>
						</div>
					</div>

				</div>

				<input type="hidden" v-model="formData.dawa_id">
				<input type="hidden" v-model="formData.countryCode">
				<div class="field-submit">
					<input type="submit" name="submit" :value="submitText" tabindex="0" @click="checkValidity()">
				</div>
			</fieldset>
		</form>

		<div class="iframe-wrapper">
			<!-- iframe -->
			<iframe v-if="openIframe" id="gatewayIframe" :src="iframeSrc" style="
				height: 100%;
				min-height: 600px;
				width: 49vw;
				max-height: calc(100vh - 90px);
				position: absolute;" :style="{ display: paymentSuccessful || paymentFailed ? 'none' : null }" width="100%"
				height="calc( 100vh - 90px)" allowtransparency="true" scrolling="yes" seamless webkitAllowFullScreen
				mozallowfullscreen allowFullScreen></iframe>

			<div v-if="paymentSuccessful" class="payment-success-screen">
				<h2 v-if="formData.paymentMethodType == 'Invoice'">
					Donationen er blevet registreret og fakturaen vil blive
					sendt
				</h2>
				<h2 v-else>Betalingen er gennemført</h2>

				<button class="reset" @click="resetForm">Påbegynd næste</button>
			</div>

			<div v-else-if="paymentFailed" class="payment-failed-screen">
				<h2>Betalingen har slået fejl</h2>

				<button class="reset" @click="resetForm">Start forfra</button>
			</div>
		</div>
		<!-- loading spinner -->
		<OfSpinner v-if="loading" />
	</div>
</template>

<style scoped>
header {
	border-bottom: 1px solid #000;
	width: 100%;
	height: 60px;
	background: #fafafc;

}

header>div {
	width: 50%;
	float: left;
	padding: 0 25px;
}

header .actions {
	margin-right: 20px;
	margin-top: 10px;
	text-align: center;
	white-space: nowrap;
}

header .actions>button {
	margin-left: 1rem;
}

form {
	width: 50%;
	float: left;
	padding: 0 25px;
}

.iframe-wrapper {
	width: 50%;
	float: left;
}

fieldset:disabled,
fieldset:disabled * {
	cursor: not-allowed !important;
}

input[type="submit"],
button.reset {
	-webkit-font-smoothing: antialiased;
	box-sizing: border-box;
	display: inline-flex;
	-webkit-box-align: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	height: 38px;
	padding: 0 18px;
	transition: all 0.1s ease-in-out 0s;
	font-size: 0.875rem;
	font-weight: 500;
	position: relative;
	border: 1px solid #D8DCE6;
	box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
	border-radius: 4px;
	min-height: 40px;
	font-family: "Roboto", sans-serif;
	cursor: pointer;
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	border-color: rgba(15, 91, 194, 0.5);
	color: #fff;
}

button.accept-address {
	grid-column: span 2/span 2;
	margin-left: 0.5rem;
	min-width: 80px;
	padding: 0 10px;
	transition: all 0.1s ease-in-out 0s;
	font-size: 0.875rem;
	font-weight: 500;
	border: 1px solid #D8DCE6;
	box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
	border-radius: 4px;
	min-height: 25px;
	font-family: "Roboto", sans-serif;
	cursor: pointer;
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	border-color: rgba(15, 91, 194, 0.5);
	color: #fff;
}

input[type="submit"]:focus,
button.reset:focus,
button.accept-address:focus {
	outline: 0;
	-webkit-box-shadow: 0 0 0 1px rgba(15, 91, 194, 0.8);
	box-shadow: 0 0 0 1px rgba(15, 91, 194, 0.8);
}

input[type="submit"]:hover,
button.reset:hover,
button.accept-address:hover {
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	border-color: rgba(15, 91, 194, 0.8);
	color: #fff;
}

button.accept-address:hover {
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 1) 0%, rgba(58, 177, 230, 1) 100%);
	border-color: rgba(15, 91, 194, 0.8);
	color: #fff;
}



.payment-method ul,
.contact-type ul {
	list-style: none;
	text-align: center;
	margin: 10px auto 20px;
	display: block;
}

.checkbox-group {
	grid-column: span 9/span 9;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
}

.payment-method ul li,
.contact-type ul li {
	margin-top: 8px;
	min-height: 30px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
	margin-right: 15px;

}

.checkbox-group li {
	margin-top: 8px;
	min-height: 30px;
	margin-right: 15px;
}

.payment-method label {
	color: #3e3f42;
	font-weight: 500;
}

.radio-group label,
.checkbox-group label {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	position: relative;
	cursor: pointer;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.field {
	margin-bottom: 5px;
	position: relative;

	display: grid;
	grid-template-columns: repeat(12, minmax(0, 1fr));
	align-items: center;

}

.field>div:first-child {
	grid-column: span 3/span 3;

}

.field>div:last-of-type {
	max-width: 400px;
	grid-column: span 9/span 9;
}

.field>small {
	grid-column: span 9/span 9;
	grid-column-start: 4;
	margin-left: 0.3rem;
	font-style: italic;
}

.field>small.no-email {
	cursor: pointer;
	text-decoration: underline;
}

.post-and-city>.field>div:last-child {
	display: flex;
}

.post-and-city>.field>div:last-child>input:last-of-type {
	margin-left: 1rem;
}

input[type="radio"],
input[type="checkbox"] {
	-webkit-appearance: none;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	font-family: "Roboto", sans-serif;
}

.check {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	float: left;
	height: 14px;
	margin-top: 0px;
	margin-right: 8px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	cursor: pointer;
	-webkit-box-ordinal-group: -1;
	-ms-flex-order: -2;
	-webkit-order: -2;
	order: -2;
}

.check:after {
	content: "";
	display: block;
	background: transparent;
	height: 6px;
	width: 6px;
	position: absolute;
	top: 4px;
	left: 4px;
	z-index: 5;
	border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
}

.check:before {
	content: "";
	border-radius: 100%;
	display: block;
	width: 14px;
	height: 14px;
	position: relative;
	top: 0;
	left: 0px;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#f6f7f9), to(#ffffff));
	background-image: -webkit-linear-gradient(90deg, #f6f7f9 0%, #ffffff 100%);
	background-image: linear-gradient(0deg, #f6f7f9 0%, #ffffff 100%);
	border: 1px solid #d8dce6;
	-webkit-box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
	box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05), inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	-ms-border-radius: 100%;
	-o-border-radius: 100%;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

input[type="checkbox"]+.check:before {
	border-radius: 4px;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}

.radio-group input[type="radio"]:checked~.check:before {
	border-color: rgba(15, 91, 194, 1);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

.radio-group input[type="radio"]:checked~.check:after {
	top: 4px;
	background: #fff;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

input[type="checkbox"]:checked+.check:before {
	border-color: rgba(15, 91, 194, 1);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: -webkit-linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	background: linear-gradient(45deg, rgba(15, 91, 194, 0.9) 0%, rgba(58, 177, 230, 0.9) 100%);
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

input[type="checkbox"]:checked+.check:after {
	background-image: url(assets/done_mini.svg);
}

input[type="checkbox"]+.check:after {
	background-image: url(assets/done_mini.svg);
	background-position: center;
	background-repeat: no-repeat;
	-webkit-background-size: contain;
	background-size: contain;
	height: 8px;
	width: 8px;
	top: 3px;
	left: 3px;
}

input:not([type="checkbox"]):not([type="radio"]):required {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAA8CAMAAAAXHsnOAAAAS1BMVEUAAAAiIiIgICAaGhp9fX0VFRUtLS07OzumpqZLS0uIiIgwMDA4ODhqamqCgoK0tLRWVlZ9fX19fX0mJiZVVVWPj497e3ujo6OysrKnwouMAAAAGXRSTlMATU1NG01HQAU6F0RBLQoGOCUSSjIlIRkRX/eZHgAAAP5JREFUSMftld1ugzAMRrE/8p9QoC3t+z/pyNZVWp1ERbtoK3GurMhHBGObbmfnLbg6veKuz+YfouIVFQ9PCj0zrTD3LxeG2ZYEOw/l/EkfzSKFxRz1VMq/AAw4/1fw7vv4UqilQU4CnQb/K/jhRMgxjKzwWTFlGGGkH4HGgNuhOgshgm7wPQLfoyiEAGrAQQhJcSNfJSHYRDWFFSXbSeYRxWsB41zrCSMfwjCNDrGLfjBYL7ZrCJMUJim0r6SqV/Ku9tK5wUplRa2soGT//+ECt1uj0XwoRXFze9cGSD0OkBIDtGVE5RLo5RLo8xLYumY+Z1XKdb/5h7Kz8458ATAoCuKj6r0BAAAAAElFTkSuQmCC);
	-webkit-background-size: 16px 20px;
	background-size: 16px 20px;
	background-position: right 2px top 4px;
	background-repeat: no-repeat;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"] {
	font-family: "Roboto", sans-serif;
	color: #444;
	border: 1px solid #bbb;
	border-radius: 4px;
	display: block;
	width: 100%;
	background: white;
	padding: 5px 10px;
	-webkit-transition: .2s all;
	transition: .2s all;
	outline: none;
	font-size: 0.875rem;
	line-height: 1em;
	height: auto;
	-webkit-appearance: none;
	box-sizing: border-box;
	border-color: #dfdbdb;
}

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

ul.dawa-suggestions {
	grid-column: span 9/span 9;
	grid-column-start: 4;

	border: 1px solid #c5c5c5;
	background: #ffffff;
	color: #333333;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	border-radius: 4px;

}

ul.dawa-suggestions li {
	cursor: pointer;
}

ul.dawa-suggestions li div {
	position: relative;
	padding: 3px 1em 3px 0.4em;
	border-radius: 4px;
}

ul.dawa-suggestions li div>span:last-child {
	float: right;
	margin-left: 0.1rem;
}

ul.dawa-suggestions li div.valid {
	color: #38b249;
	background-color: rgba(56, 178, 73, .05);
}

ul.dawa-suggestions li div.suggestion {
	color: #636363;
	background-color: rgba(158, 160, 165, .05);
}

ul.dawa-suggestions li div:hover {
	font-weight: 500;
}

p.error {
	margin: .5rem 0;
	color: #E6492D;
}

.field-submit {
	margin-top: 5px;
	text-align: center;
}

.payment-success-screen h2 {
	line-height: 1;
	margin: 1rem 0;
	font-size: 28px;
	color: #38b249;
	font-weight: 500;
}

.payment-failed-screen h2 {
	line-height: 1;
	margin: 1rem 0;
	font-size: 28px;
	color: #E6492D;
	font-weight: 500;
}
</style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html {
	overflow-y: scroll;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

body {
	font-family: "Roboto", sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 1.4em;
	font: 400 13px/1.4em "Roboto", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #fafafc;
}
</style>